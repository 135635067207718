import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PortalAuthenticationService } from '../_services/dx-portal-auth.service';
import { TextFieldComponent } from '../_shared/dx-components/Forms/FormFields/TextField/textfield.component';
import { DxControlUtilsService } from '../_shared/dx-components/_common/dx-control-utils.service';

@Component({
  selector: 'dx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  title = 'DemandXchange';
  showInputViaEmail: boolean = true;
  loginForm!: FormBuilder;
  returnUrl!: string;
  @ViewChild("emailLogin") emailField!: TextFieldComponent;
  constructor(public authSvc: PortalAuthenticationService,
      private actiRoute: ActivatedRoute,
      private route: Router,
      private controlSvc: DxControlUtilsService)
  {

  }
  ngOnInit() {
    
    this.returnUrl = this.actiRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  showLoginViaEmail(){
     this.showInputViaEmail = !this.showInputViaEmail;
  }

  login(){
    //console.log(this.emailField.getValue());
    let emailField = this.controlSvc.getComponent("emailLogin");
    let orgIdField = this.controlSvc.getComponent("orgId");
    let passwordField = this.controlSvc.getComponent("userpw");
    this.authSvc.showLoader = true;
    this.authSvc.login(emailField.getValue(), passwordField.getValue(), orgIdField.getValue()).subscribe((isSuccess: Number) => {
      if (isSuccess == 0) {
        this.authSvc.showLoader = false;
        this.route.navigate(['Leads']);
      }
    });
  }

}
