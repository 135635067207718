import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PortalAuthenticationService } from '../_services/dx-portal-auth.service';


@Injectable()
export class AuthSsoInterceptor implements HttpInterceptor {
  constructor(private portalAuthSvc: PortalAuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    let loggedOnUser = this.portalAuthSvc.getLoggedOnUser;      
    if (loggedOnUser) {      
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${loggedOnUser.oauthToken?.id_token}`)
      });
    }    
    return next.handle(request)
  }
}
