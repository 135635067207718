import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ILoggedOnUser } from './_interface/portal/loggedUser.interface';
import { PortalAuthenticationService } from './_services/dx-portal-auth.service';
import { IdxAdjacentItems } from './_shared/dx-components/_interfaces/icontrols-common/imenubar.interface';
import { IdxMenuItem } from './_shared/dx-components/_interfaces/iprimeng/irefobject.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  appMenuItems!: IdxMenuItem[];
  rightMenuBarItems!: IdxAdjacentItems[];
  title = 'DemandXchange';
  loggedOnUser!: ILoggedOnUser | null;
  constructor(public authSvc: PortalAuthenticationService,
      private router: Router)
  {
    this.authSvc.loggedOnUser.subscribe(user => this.loggedOnUser = user);
    if(!this.loggedOnUser){
       this.logout();
    }
  }
  ngOnInit() {
    // if(this.loggedOnUser){
    //   this.routeToApp("Leads");
    // }
    this.rightMenuBarItems = [
      {
         id: "portal-user-avatar",
         controlType: "avatar",
         config: {}
      },
      {
          id: "portal-search",
          controlType: "search",
          config: {}
      },
      {
        id: "portal-button-logout",
        controlType: "button",
        config: {
           onClick: this.logout.bind(this),
           icon: "pi pi-power-off",
           text:  "Logout"
        }
      },
    ]

    this.appMenuItems  = [
      {
          label:'DemandXchange',
          icon:'pi pi-fw pi-list',
          items:[
              // {
              //     label:'Users Admin',
              //     icon:'pi pi-fw pi-user',
              //     items:[
              //         {
              //             label:'Users',
              //             icon:'pi pi-fw pi-book',
              //             command: this.routeToApp.bind(this, "UserManagement")
              //         },
              //         {
              //             label:'Groups',
              //             icon:'pi pi-fw pi-users',
              //             command: this.routeToApp.bind(this, "UserManagement/user-group")
              //         },
              //         {
              //             label:'Roles',
              //             icon:'pi pi-fw pi-user-edit',
              //             command: this.routeToApp.bind(this, "UserManagement/user-role")
              //         },
              //         {
              //             label:'Permissions',
              //             icon:'pi pi-fw pi-user-plus'
              //         }
              //     ]
              // },
              // {
              //     label:'Organization',
              //     icon:'pi pi-fw pi-sitemap',
              //     command: this.routeToApp.bind(this, "Organization")
              // },
              // {
              //   label:'Leads Monitor',
              //   icon:'pi pi-fw pi-sitemap',
              //   command: this.routeToApp.bind(this, "LeadsMonitor")
              // },
              {
                label:'DX Leads',
                icon:'pi pi-fw pi-sitemap',
                command: this.routeToApp.bind(this, "Leads")
              },
              {
                label:'DX PowerBi',
                icon:'pi pi-fw pi-sitemap',
                items:[
                          {
                              label:'Original',
                              icon:'pi pi-fw pi-book',
                              command: this.routeToApp.bind(this, "PowerBi")
                          },
                          {
                              label:'Light',
                              icon:'pi pi-fw pi-users',
                              command: this.routeToApp.bind(this, "PowerBi/light")
                          },
                          {
                              label:'Convertr',
                              icon:'pi pi-fw pi-user-edit',
                              command: this.routeToApp.bind(this, "PowerBi/convertr")
                          }
                ]
              }
          ]
      }
    ];
    
  }
  routeToApp(app: string){
    this.router.navigate([app])
  }
  logout(){
     this.authSvc.logout();
     this.router.navigate(['/login']);
  }
}
