<div class="login-form-body" style="width: 500px;">
    <dx-panel [content]="loginInputs" [header]="loginHeader">
        <ng-template #loginHeader>
            <div>DemandXchange</div>
        </ng-template>
        <ng-template #loginInputs>
            <div class="body-input-login">
                <div class="input-login email-field">
                    <dx-textfield #emailLogin id="emailLogin" label="Email"></dx-textfield>
                </div>
                <div *ngIf="showInputViaEmail" class="input-login via-email-login">
                    <div class="input-login">
                        <dx-textfield id="orgId" label="Account ID"></dx-textfield>
                    </div>
                    <div class="input-login">
                        <dx-textfield type="password" id="userpw" label="Password"></dx-textfield>
                    </div>
                </div>
                <div class="input-login log-link-buttons">
                    <div>
                        <dx-linkfield id="option" label="Login via email" (onClick)="showLoginViaEmail()"></dx-linkfield>
                     </div>
                    <div>
                        <dx-linkfield *ngIf="showInputViaEmail" id="option" label="Sign In" (onClick)="login()"></dx-linkfield>
                    </div>

                </div>
                <div class="input-login via-provider-auth">
                    <div>
                        <dx-button type="outline"></dx-button>
                    </div>
                </div>
            </div>
        </ng-template>
    </dx-panel>
</div>

