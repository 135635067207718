import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
// import { IdxMenuItem } from './../_shared/dx-components/_interfaces/iprimeng/irefobject.interface';

@Component({
  selector: 'dx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  //appMenuItems!: IdxMenuItem[];
  title = 'DXPortal.Angular';
  constructor(private router: Router){

  }
  ngOnInit() {
    // this.appMenuItems  = [
    //   {
    //       label:'DemandXchange',
    //       icon:'pi pi-fw pi-list',
    //       items:[
    //           {
    //               label:'Users Admin',
    //               icon:'pi pi-fw pi-user',
    //               items:[
    //                   {
    //                       label:'Users',
    //                       icon:'pi pi-fw pi-book',
    //                       command: this.routeToApp.bind(this, "UserManagement")
    //                   },
    //                   {
    //                       label:'Users Groups',
    //                       icon:'pi pi-fw pi-users'
    //                   },
    //                   {
    //                       label:'Users Role',
    //                       icon:'pi pi-fw pi-user-edit'
    //                   },
    //                   {
    //                       label:'Users Access',
    //                       icon:'pi pi-fw pi-user-plus'
    //                   }
    //               ]
    //           },
    //           {
    //               label:'Organization',
    //               icon:'pi pi-fw pi-sitemap',
    //               command: this.routeToApp.bind(this, "Organization")
    //           },
    //           {
    //             label:'Leads Monitor',
    //             icon:'pi pi-fw pi-sitemap',
    //             command: this.routeToApp.bind(this, "LeadsMonitor")
    //           }
    //       ]
    //   }
    // ];
  }
  routeToApp(app: string){
    this.router.navigate([app])
  }
}
