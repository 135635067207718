<p-table [columns]="columns" [value]="dataSource.data" [tableStyle]="{'min-width': '50rem'}"
[paginator]="true"
[rows]="20"
[showCurrentPageReport]="false"
[rowsPerPageOptions]="[20, 40, 60, 100]"
[lazy]="true" (onLazyLoad)="pageLoadEvent($event)"
[loading]="loading"
[totalRecords]="dataSource.dataTotalCount"
>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <div *ngIf="col.type === 'string'">{{rowData[col.field]}}</div>
                <div *ngIf="col.type === 'number'">{{rowData[col.field]}}</div>
                <div *ngIf="col.type === 'enum'">{{ col.enum[rowData[col.field]]}}</div>
            </td>
        </tr>
    </ng-template>
</p-table>
<!-- currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" -->