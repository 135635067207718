import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, of, Subject, timer, BehaviorSubject } from 'rxjs';
import { GlobalsService } from '../_core/globals.service';
import { ILoggedOnUser } from '../_interface/portal/loggedUser.interface';
//import jwtDecode from 'jwt-decode';
import { PortalGraphQlApiService } from './dx-graphql-api.service';
import { DxControlUtilsService } from '../_shared/dx-components/_common/dx-control-utils.service';
import { IOAuthToken } from '../_interface/portal/oAuth.interface';
import { LoggedOnUserModel } from '../_models/authModels/loggedUser.model';
import { HttpServiceHeader } from '../_constants/user.constants';

@Injectable()
export class PortalAuthenticationService {
  private currentUserSubject: BehaviorSubject<LoggedOnUserModel | null>;
  public loggedOnUser: Observable<LoggedOnUserModel | null>;
  unauthorizedSessionUrl!: string;
  keyUser: string = 'dx-current-user';
  showLoader: boolean = false;
  constructor(
    private http: HttpClient,
    private globalsService: GlobalsService,
    private graphQlAuthSvc: PortalGraphQlApiService,
    private dxSvcHelper: DxControlUtilsService
  ) { 
    this.currentUserSubject = new BehaviorSubject<LoggedOnUserModel | null>(
       JSON.parse(String(localStorage.getItem(this.keyUser)))
    );
    this.loggedOnUser = this.currentUserSubject.asObservable();
  }

  public get getLoggedOnUser(): ILoggedOnUser | null {
    return this.currentUserSubject.value;
  }

  private loginUser(email: string, password: string, orgId: number): Observable<IOAuthToken> {
    var loginModel =  {
      "email": email, //"mathew.tupas@demandscience.com",
      "password": password, //"JGI6YUEc56",
      "orgId": orgId
    };

    return this.http.post<IOAuthToken>(`${this.globalsService.env.authUrl}/login`, loginModel);
  }

  public login(email: string, password: string, orgId: number): Observable<number> {
         return new Observable<number>((observer => {
             this.loginUser(email, password, orgId).subscribe((oathTokenCallBack: IOAuthToken) => {
                 this.graphQlAuthSvc.authuserToken = oathTokenCallBack["id_token"];
                 this.graphQlAuthSvc.initApolloConfig();
                 this.graphQlAuthSvc.apolloClient.watchQuery({
                  query: this.graphQlAuthSvc.getLoggedOnUser(),
                      variables:{
                        input: { email: "mathew.tupas@demandscience.com" }
                      }
                  }).subscribe(userDetails => {
                      //console.log(res);
                      let loggedUser: ILoggedOnUser = new LoggedOnUserModel();
                      //let userObj = {} as ILoggedOnUser;
                      loggedUser = this.dxSvcHelper.applyObject(loggedUser, this.graphQlAuthSvc.getDataResponse("getUserByEmail", userDetails).items) as ILoggedOnUser;
                      //let currentDate = new Date();
                      loggedUser.oauthToken = oathTokenCallBack; //{  access_token: oathTokenCallBack["id_token"],
                    //         token_type: oathTokenCallBack["token_type"],
                    //         //expires_in : oathTokenCallBack["expires_in"],
                    //         //expires_on : new Date(currentDate.setTime(currentDate.getTime() + (oathTokenCallBack["expires_in"] * 1000))),
                    //         dateCreated : new Date(),
                    //         user_guid: oathTokenCallBack["user_guid"],
                    //         refresh_token: oathTokenCallBack["refresh_token"]
                    // } as IOAuthToken
                    localStorage.setItem(this.keyUser, JSON.stringify(loggedUser));
                    this.currentUserSubject.next(loggedUser);
                    observer.next(0);
                  }, err => {
                      console.log(err);
                      observer.next(-1);
                  });
                
             });
         }));
  }
  private getLoggedOnUserUsingToken(accessToken: string): Observable<ILoggedOnUser> {
    let authenticatedHeaders = {
      headers: HttpServiceHeader.jsonHeaders.headers.set('Authorization', `Bearer ${accessToken}`)
    };

    return this.http.post<ILoggedOnUser>(`${this.globalsService.env.authUrl}/getloggedonuserusingtoken`, null, authenticatedHeaders);
  }
  public pxLogin(email: string, password: string): Observable<any>{
    var loginModel =  {
      "email": email,
      "password": password,
    };

    return new Observable<any>((observer)=>{
      this.http.post<IOAuthToken>(`${this.globalsService.env.authUrl}/login`, loginModel).subscribe((oathTokenCallBack: IOAuthToken) => {
          this.getLoggedOnUserUsingToken(oathTokenCallBack.id_token).subscribe(data => {
              let loggedUser: ILoggedOnUser = new LoggedOnUserModel();
              loggedUser = this.dxSvcHelper.applyObject(loggedUser, data) as ILoggedOnUser;
              loggedUser.oauthToken = oathTokenCallBack;
              localStorage.setItem(this.keyUser, JSON.stringify(loggedUser));
              this.currentUserSubject.next(loggedUser);
              observer.next(loggedUser);
          });
      })
       
   })
  }


  hasClaim(claimType: string, claimValue?:string){
       let hasClaim: boolean = false;

      // if (typeof claimType === "string") {
      //   let userAuth: ILoggedOnUser = this.getCachedUser();
      //   let claimSuper = false;
      //   if(userAuth){
      //     if(userAuth.userType === 1){
      //       let claimPartnerSuper = userAuth.claims.find(c => c.claimType.toLowerCase() == "superpartner");
      //       if(claimPartnerSuper){
      //         claimSuper = true;
      //         let partnersMenu = ["superpartner","purexchange","px.dashboard","px.partnercampaigns","px.leads","px.leadsfile"];
      //         hasClaim = claimPartnerSuper.claimValue === "canSuper" && (partnersMenu.indexOf(claimType) > -1);
      //       }
      //     }else{
      //       let claimObjSuper = userAuth.claims.find(c => c.claimType.toLowerCase() == "super" || userAuth.isAdmin ===  true);
      //       if(claimObjSuper){
      //         claimSuper = true;
      //         hasClaim = claimObjSuper.claimValue === "canSuper"
      //       }
      //     }
      //   }

      //   if(!claimSuper){
      //     hasClaim = this.isClaimValid(claimType, claimValue);
      //   }
      // }
      // else {
      //   let claims: string[] = claimType;
      //   if (claims) {
      //     for (let index = 0; index < claims.length; index++) {
      //       hasClaim = this.isClaimValid(claims[index]);
      //       if (hasClaim) {
      //         break;
      //       }
      //     }
      //   }
      // }

      //TODO: Isolate to lead delivery and px
     /* if(claimType.toLocaleLowerCase() === "leaddelivery"){
        let ladClaimObject = this.loggedOnUser.claims.find(c => c.claimType.toLowerCase() == "leaddelivery");
        if(!ladClaimObject){
            hasClaim = false;
        }
      }

      if(claimType.toLocaleLowerCase() === "purexchange"){
        let ladClaimObject = this.loggedOnUser.claims.find(c => c.claimType.toLowerCase() == "purexchange");
        if(!ladClaimObject){
            hasClaim = false;
        }
      }*/

    return hasClaim;
  }


  // isClaimValid(claimType: string, claimValue?:string): boolean{
  //   let isValidClaim: boolean = false;
  //   let userAuth: ILoggedOnUser = this.loggedOnUser;

  //   if(userAuth){
      
  //      if(claimType.indexOf("=") >= 0){
  //       let words: string[] = claimType.split("=");
  //       claimType = words[0].toLowerCase();
  //       claimValue = words[1];
  //      }else{
  //       claimType = claimType.toLowerCase();
  //       claimValue = claimValue ? claimValue : "true";
  //      }

  //       let claimObj = userAuth.claims.find(c => c.claimType.toLowerCase() == claimType);
  //       if(claimObj && claimObj.claimValue.indexOf(":") >= 0){
  //         let valWords: string[] = claimObj.claimValue.split(":");
  //         valWords.forEach(v => {
  //             if(claimValue === v){
  //                 isValidClaim = true;
  //             }
  //         });
  //       }else{
  //         isValidClaim = userAuth.claims.find(c =>
  //         c.claimType.toLowerCase() == claimType &&
  //         c.claimValue == claimValue) != null;
  //       }
  //   }
  //   return isValidClaim;
  // }

  validateMultiPleClaimValue(claimValue: string){

  }

  private _timeoutInSeconds: number = 5;//3000;
  timer!: Observable<number>;
  onTimeOutExpired: Subject<number> = new Subject<number>();

  startSession(){
      
      let inSec = this._timeoutInSeconds * 60000;
      this.timer = timer(2000, inSec);
      this.timer.subscribe(((num: number) => {
         if(num !== 0){
          this.refreshTokenRequest();
         }
      }).bind(this));
  }

  refreshTokenRequest(){
    //  if(this.loggedOnUser){
    //     let refToken = this.loggedOnUser.oauthToken["refresh_token"];
    //     this.http.post<IOAuthToken>(`${this.globalsService.env.authUrl}/refreshtoken`, {
    //         user_guid: this.loggedOnUser.oauthToken["user_guid"],
    //         refresh_token: refToken
    //     }).subscribe(objRes => {
    //       this.loggedOnUser.oauthToken = {  access_token: objRes["id_token"],
    //         token_type: objRes["token_type"],
    //         expires_in : objRes["expires_in"],
    //         expires_on : new Date(),
    //         dateCreated : new Date(),
    //         user_guid: objRes["user_guid"],
    //         refresh_token: refToken
    //       } as IOAuthToken
    //       this.setCachedUser(this.loggedOnUser);
    //     });
    //  }
  }

  resetPassword(email: string) {
    // let userModel = {
    //     "email": email
    // }
    // return this.http.post<any>(`${ this.globalsService.env.authUrl }/membership/resetpassword`, userModel)
  }

  changePassword(formData: any) {
    // let changePasswordData = {
    //   "email": formData.username,
    //   "old_password": formData.oldpassword,
    //   "new_password": formData.newpassword,
    //   "user_guid": this.loggedOnUser.oauthToken.user_guid
    // };

    // return this.http.post<any>(`${this.globalsService.env.authUrl}/membership/changepassword`, changePasswordData);
  }


  public setUnauthorizedSessionUrl(url: string): void {
    this.unauthorizedSessionUrl = url;
  }

  public getCachedUser(): ILoggedOnUser{
        return JSON.parse(String(localStorage.getItem(this.keyUser)));
  }
  logout(){
    localStorage.removeItem(this.keyUser);
    this.currentUserSubject.next(null);
  }
}
