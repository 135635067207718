import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { HttpLink } from 'apollo-angular/http';
import { GlobalsService } from '../_core/globals.service';
import { PortalAuthenticationService } from './dx-portal-auth.service';
import { ApolloClient, NormalizedCacheObject ,ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class PortalGraphQlApiService {

    constructor(private httpLink: HttpLink, private globalSvc: GlobalsService) {
      // const ws = new WebSocketLink({
      //   uri: `ws://dev-dx-graphql-api-v1.pureincubation.com/graphql`,
      //   options: {
      //     reconnect: true
      //   }
      // });
    }
    public authuserToken: string = "";
    apolloClient!: ApolloClient<NormalizedCacheObject>
    initApolloConfig(){
        let uri = this.globalSvc.env.dxGraphQlUri;
        const headers = new HttpHeaders; 
        this.apolloClient = new ApolloClient({
          link: this.httpLink.create({ uri: uri,  headers: headers.append('Authorization', `bearer ${this.authuserToken}`)}),
          cache: new InMemoryCache(),
      });
    }
    
    getDataResponse(gqlName: string, dataResp: any){
       return dataResp.data[gqlName].data;
    }

    getLoggedOnUser(){
      return gql`query getUserByEmail($input: UserRequestModel) {
        getUserByEmail(input: $input) {
          hasErrors
          data {
            items {
              id
              email
              firstName
              lastName
              isActive
              isDeleted
              isLocked
              isPasswordResetRequired
              providerType
              providerTypeName
              dxClaims
              groups{
                id
                groupName
                isActive
                isDeleted
              }
              createdOn
              createdBy
              updatedOn
              updatedBy
            }
          }
        }
      }`
    }
}