import { NgModule } from '@angular/core';
import {MenubarModule} from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ToolbarModule} from 'primeng/toolbar';
import {SplitterModule} from 'primeng/splitter';
import {TabViewModule} from 'primeng/tabview';
import {BadgeModule} from 'primeng/badge';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [
    MenubarModule,
    InputTextModule,
    ButtonModule,
    AvatarModule,
    AvatarGroupModule,
    TableModule,
    PanelModule,
    ToolbarModule,
    SplitterModule,
    TabViewModule,
    BadgeModule,
    PasswordModule,
    CheckboxModule,
    InputNumberModule,
    DropdownModule,
    ProgressSpinnerModule
  ],
  declarations: [],
  exports: [
    MenubarModule,
    InputTextModule,
    ButtonModule,
    AvatarModule,
    AvatarGroupModule,
    TableModule,
    PanelModule,
    ToolbarModule,
    SplitterModule,
    TabViewModule,
    BadgeModule,
    PasswordModule,
    CheckboxModule,
    InputNumberModule,
    DropdownModule,
    ProgressSpinnerModule
  ],
  providers: []
})
export class PrimeNgAngularModule { }
