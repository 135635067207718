import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject} from 'rxjs';

export class DataSourceModel {
    data: any[] = []
    onSuccessCallBack = new Subject<any[]>();
    onAfterResponse = new Subject<{}>();
    onBeforeReponse = new Subject<{}>();
    onErrorCallBack = new Subject<any>();
    dataTotalCount: number = 0;
    params: any = {};
    isProxy: boolean = false;
    service: any;
    method!: string;
    exportParams!: any;
    exportMethod!: string;
    visibleColumns: number[] = [];
    onExportCallBack = new Subject<boolean>();
    constructor(service?: any) {
        this.service = service;
    }
    doExport(){
        let paramFilter: any[] = this.params.filterCriteria ? this.params.filterCriteria : [];
        this.exportParams.filterCriteria = paramFilter.concat(this.exportParams.filterCriteria);
        this.exportParams.columns = this.visibleColumns;
        this.exportParams.pagination = this.params.pagination;
        this.service[this.exportMethod](this.exportParams).subscribe((objCallack: any)=>{
            this.onExportCallBack.next(objCallack);
        });
    }
    doSearch(){

    }

    onFilterLoadData(): void{
        
    }
    onPageEventLoad(event: any): void {

    }
    loadData(): void{
        if(this.isProxy){
            this.service[this.method](this.params).subscribe(((poflist: any) => {
               this.onBeforeReponse.next({data: poflist, params: this.params});
               if(poflist){ 
                this.data = poflist["items"];
                this.dataTotalCount = poflist["totalCount"];
                this.onSuccessCallBack.next(poflist);
               }
               this.onAfterResponse.next({data: poflist, params: this.params});
            }).bind(this),(errorData:any)=>{
                this.onErrorCallBack.next(errorData)
            });
        }
    }
}
