export const environment = {
    production: true,
    authUrl: "https://dev-dxuser-api.pureincubation.com/auth",
    dxGraphQlUri: "https://dev-dxuser-graphql-api.pureincubation.com/graphql",
    powerbiConfig: {
      powerbiUrl: 'https://dev-px2-powerbi-api.pureincubation.com/api',
      powerbiServiceAuth: {
        tokenCS: 'bXFhbjBlaG04bzFlN3RmdWpmc280c3RsajpqYm9pMjJtNnB1Y2tiNGJhYmkycmVucXZhc3Q5NHRyYjl0c24yZGJkZW9ycGFiZzdwaTQ=',
        tokenURL: 'https://dev-bre-pureincubation.auth.us-east-1.amazoncognito.com/oauth2/token',
        authBodyFormat: 'grant_type=client_credentials&scope=bre.api.com/api.execute'
      },
      defualtWorspaceId: '7e884027-e236-4dbe-a2d5-e98494ab6dd9',
      defaultReportId: '78244952-92cf-444b-bab7-01d40e94312a',
      lightReportId: '5b4c5ccf-b86d-4dd6-b689-c8cd58a2c10d',
      convertrReportId: '3aaf7f17-b04e-491d-8216-a188dbee506c'
    }
  };
  