import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DataSourceModel } from '../_dxmodel/data-source';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'dx-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {
    @Input() dataSource!: DataSourceModel
    products: any[] = [];
    @Input() columns!: any[];
    @Input() loading: boolean = false;
    pagination: any = {
        currentPage: 1,
        skip: 0,
        take: 0   
    }
    ngOnInit() {
        if(!this.dataSource){
            this.dataSource = new DataSourceModel();
        }
        if(this.dataSource){

        }
        this.loading = true;
    }
    pageLoadEvent(event: LazyLoadEvent) {  
        this.loading = true;

        setTimeout(() => {
            this.loading = false;
            if (this.dataSource) {
                
            }
        }, 1000);
    }

}
